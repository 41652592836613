import React, { useState } from 'react';
import { maxBy, minBy } from 'lodash';
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
} from 'victory';
import styled from 'styled-components';

import { formatTwrTicks, periodToDate } from '@formue-app/core';

import { bodyFontStack } from '../../../fonts';
import { PortfolioReportingChartTooltip } from './PortfolioReportingChartTooltip';

const Container = styled.div``;

export const PortfolioReportingChart = (props) => {
  const {
    dataExtractor = 'twr',
    data,
    indexData,
    colorSet,
    indexColorSet,
    height = 160,
  } = props;
  const [tooltipEnabled, setTooltipEnabled] = useState(true);

  const portfolioChartData = data.map((item) => ({
    ...item,
    x: periodToDate(item.p),
  }));

  const indexChartData = indexData.map((index) => {
    const indexStartPadding = portfolioChartData.find(
      (item) => item.p === index.index[0]?.period
    );

    return index.index.map((item) => ({
      padding: indexStartPadding?.twr,
      x: periodToDate(item.period),
      y: item.rate,
    }));
  });

  if (!portfolioChartData) return null;

  const maxValue = maxBy(portfolioChartData, dataExtractor);
  const minValue = minBy(portfolioChartData, dataExtractor);

  if (!minValue || !maxValue) return null;
  // If the minimum value isn't negative, we want to "push" the zero axis up a bit
  // We do this only to get a better visual experience
  const y0 = minValue[dataExtractor] >= 0 ? 0 - maxValue[dataExtractor] / 7 : 0;

  let chartLabels = portfolioChartData.map((value) => value.x);

  return (
    <Container>
      <VictoryChart
        domainPadding={{
          x: [40, 0],
          y: [30, 20],
        }}
        padding={{ bottom: 0, top: 0, left: 0 }}
        animate={false}
        height={height}
        containerComponent={
          <VictoryVoronoiContainer
            disable={!tooltipEnabled}
            voronoiDimension="x"
            constrainToVisibleArea={true}
            voronoiBlacklist={indexChartData.map((_, i) => `indexes-${i}`)}
            labels={(item) => formatTwrTicks(item.twr)}
            labelComponent={
              <PortfolioReportingChartTooltip
                color={colorSet[2]}
                backgroundColor={colorSet[5]}
                lineColor={colorSet[4]}
                dataExtractor={dataExtractor}
                indexData={indexData}
                indexColorSet={indexColorSet}
              />
            }
            style={{ display: 'flex' }}
          />
        }
      >
        <VictoryAxis
          crossAxis={false}
          dependentAxis
          offsetX={35}
          tickFormat={(tick) => formatTwrTicks(tick)}
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: ({ tick }) => (tick === 0 ? colorSet[4] : colorSet[3]),
            },
            tickLabels: {
              fontSize: 8,
              fontWeight: 400,
              padding: 0,
              fill: colorSet[1],
              fontFamily: bodyFontStack,
              textAnchor: 'end',
            },
          }}
        />
        <VictoryAxis
          offsetY={26}
          scale="time"
          padding={{ right: 0, left: 0 }}
          tickValues={chartLabels}
          tickFormat={(x) => {
            if (x.getMonth() === 6) return x.getFullYear();
          }}
          standalone={false}
          style={{
            axis: { stroke: 'transparent' },
            tickLabels: {
              fontSize: 8,
              fontWeight: 400,
              fill: colorSet[1],
              fontFamily: bodyFontStack,
              textAnchor: 'middle',
            },
          }}
        />
        <VictoryArea
          y={dataExtractor}
          y0={(d) => y0}
          standalone={false}
          data={portfolioChartData}
          interpolation="monotoneX"
          style={{
            data: {
              stroke: colorSet[2],
              strokeWidth: 1.5,
              fill: 'transparent',
            },
          }}
        />
        {indexChartData.map((data, i) => (
          <VictoryArea
            name={`indexes-${i}`}
            key={i}
            data={data}
            y={(d) => d.y + d.padding}
            interpolation="monotoneX"
            style={{
              data: {
                stroke: indexColorSet[i % indexColorSet.length],
                strokeWidth: 1.5,
                fill: 'transparent',
              },
            }}
          />
        ))}
      </VictoryChart>
    </Container>
  );
};
