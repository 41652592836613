import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { selectors, formatMoney, useResource } from '@formue-app/core';

import { GridElement } from '../../../../components/layout';
import { H4, H3, Paragraph } from '../../../../components/texts';
import { TableList } from '../../../../components/lists';
import {
  notDesktopCondition,
  mobileCondition,
  printCondition,
} from '../../../../constants/media';
import { accent, backgroundMain } from '../../../../constants/colors';
import {
  SPACING_16,
  SPACING_24,
  SPACING_32,
} from '../../../../constants/spacing';
import { CenteredActivityIndicator } from '../../../../components/common/ActivityIndicator';

const StyledGridElement = styled(GridElement)`
  @media ${notDesktopCondition} {
    grid-column: span 12;
  }

  @media ${printCondition} {
    margin-bottom: ${SPACING_32};
  }
`;

const SectionSpacing = styled(GridElement)`
  margin-top: 22px;
  margin-bottom: 40px;

  @media ${notDesktopCondition} {
    margin-top: 12px;
    margin-bottom: 36px;
  }
`;

const {
  entities: {
    taxDetails: { allTaxDetailsSelector },
  },
} = selectors;

const typeTranslation = {
  RecurringFee: 'Årlig honorar for helhetlig rådgivning og formuesforvaltning',
  OneTimeFee: 'Engangshonorar',
  RetrocessionToClient: 'Returprovisjoner overført til din kundekonto',
};

const { createErrorSelector } = selectors.api;

const errorSelector = createErrorSelector(['TAX_DETAILS/INDEX']);

export const ReportPage = (props) => {
  const { year, user } = props;
  const loading = useResource([
    { type: 'TAX_DETAILS/INDEX', args: { ssid: user, year: year } },
  ]);
  const error = useSelector(errorSelector);
  const allTaxDetails = useSelector(allTaxDetailsSelector) || [];

  const allFee = allTaxDetails
    .filter((item) =>
      item.year ? item.year.toString() === year.toString() : false
    )
    .filter((item) => parseInt(item.ssid) === parseInt(user))
    .filter((item) => item.type === 'fee');
  const allIncome = allTaxDetails
    .filter((item) =>
      item.year ? item.year.toString() === year.toString() : false
    )
    .filter((item) => parseInt(item.ssid) === parseInt(user))
    .filter((item) => item.type === 'income');

  const totalAmountFee = allFee
    .map((item) => item.amount)
    .reduce((a, c) => a + c, 0);
  const totalDeductibleAmountFee = allFee
    .map((item) => item.deductibleAmount)
    .reduce((a, c) => a + c, 0);

  const totalAmountIncome = allIncome
    .map((item) => item.amount)
    .reduce((a, c) => a + c, 0);

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  let valueTableColumns = [
    {
      key: 'title',
      label: 'Type',
      width: '50%',
    },
    {
      key: 'value',
      label: 'Beløp',
      width: '25%',
      type: 'number',
    },
    {
      key: 'deductibleValue',
      label: 'Fradragsberettiget Beløp',
      width: '25%',
      type: 'number',
    },
  ];

  if (mobileSize) {
    valueTableColumns = [
      {
        key: 'title',
        label: 'Type',
        width: '50%',
      },
      {
        key: 'deductibleValue',
        label: 'Fradragsberettiget Beløp',
        width: '50%',
        type: 'number',
      },
    ];
  }

  return (
    <>
      <StyledGridElement columns={6}>
        <H3 style={{ marginBottom: SPACING_16 }}>
          Fradragsberettigede honorarer
        </H3>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          Kostnader i forbindelse med forvaltning av verdipapirer som kan gi
          skattepliktig inntekt, er fradragsberettige i skattemeldingen, se
          Skatte-ABC 2024, avsnittet om ”Gebyr til banker, verdipapirregister
          mv.”, punkt G-3-2 ”Forvaltningsgebyr”. Kostnader som nevnt, kommer til
          fradrag etter hvert som man pådrar seg en ubetinget forpliktelse til å
          betale dem. Det vil si at honorar for 2024 skal medtas på
          skattemeldingen for dette året, selv om det først blir
          fakturert/betalt senere. I år er fradrag innrapportert og er
          forhånsutfylt på skattemeldingen din{' '}
        </Paragraph>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          Privatpersoner vil finne en oversikt over fradragsberettigede
          honorarer for 2024 i tabellen nedenfor. For selskaper er faktura for
          forvaltningshonorar tilgjengelig på kundenett. Det årlige honoraret er
          blant annet betaling for forvaltning av portefølje, finansiell
          rådgivning, rapportering og annen oppfølging knyttet til porteføljen.
          Eventuelt engangshonorar består av rådgivningshonorar og
          struktureringshonorar.
        </Paragraph>
        <Paragraph>
          Rådgivningshonoraret er betaling for utarbeidelse av formuesplan,
          innledende gjennomgang med advokat, samt implementering av
          porteføljen. Struktureringshonoraret dekker kostnadene som medgår til
          å drifte illikvide investeringer under aktivaklassene eiendom og
          private equity i porteføljene med kapitalinnkallinger og
          kapitalutbetalinger.
        </Paragraph>
      </StyledGridElement>

      <SectionSpacing columns={12}>
        {loading ? (
          <CenteredActivityIndicator background={backgroundMain} />
        ) : error ? (
          <>
            <H4
              style={{
                color: accent.raspberry1,
                marginBottom: SPACING_32,
              }}
            >
              Noe gikk galt ved henting av data. Last inn siden på nytt eller ta
              kontakt med kundeservice.
            </H4>
            <TableList
              sortable={false}
              columns={valueTableColumns}
              rows={[
                {
                  title: 'Samlet sum fradragsberettigede honorarer',
                  value: '–',
                  deductibleValue: '–',
                },
              ]}
              keyExtractor={(item, index) => `tax-honorar-${index}`}
            />
          </>
        ) : (
          <TableList
            sortable={false}
            columns={valueTableColumns}
            rows={[
              ...allFee.map((item) => ({
                title: typeTranslation[item.name],
                value: formatMoney(item.amount),
                deductibleValue: formatMoney(item.deductibleAmount),
              })),
              {
                title: 'Samlet sum fradragsberettigede honorarer',
                value: formatMoney(totalAmountFee),
                deductibleValue: formatMoney(totalDeductibleAmountFee),
              },
            ]}
            keyExtractor={(item, index) => `tax-honorar-${index}`}
          />
        )}
      </SectionSpacing>

      <StyledGridElement columns={6}>
        <H3 style={{ marginBottom: SPACING_16 }}>Skattepliktige inntekter</H3>
        <Paragraph>
          Eventuelle inntekter som følger av kapitalplasseringer er
          skattepliktige. Summen av dine mottatte returprovisjoner fra Formue
          for 2024 er listet opp nedenfor.
        </Paragraph>
      </StyledGridElement>

      <SectionSpacing columns={12}>
        {loading ? (
          <CenteredActivityIndicator background={backgroundMain} />
        ) : error ? (
          <>
            <H4
              style={{
                color: accent.raspberry1,
                marginBottom: SPACING_32,
              }}
            >
              Noe gikk galt ved henting av data. Last inn siden på nytt eller ta
              kontakt med kundeservice.
            </H4>
            <TableList
              sortable={false}
              columns={[
                {
                  key: 'title',
                  label: 'Type',
                  width: '75%',
                },
                {
                  key: 'value',
                  label: 'Beløp',
                  width: '25%',
                  type: 'number',
                },
              ]}
              rows={[
                {
                  title: 'Samlet sum mottatte returprovisjoner',
                  value: '–',
                },
              ]}
              keyExtractor={(item, index) => `tax-earnings-${index}`}
            />
          </>
        ) : (
          <TableList
            sortable={false}
            columns={[
              {
                key: 'title',
                label: 'Type',
                width: '75%',
              },
              {
                key: 'value',
                label: 'Beløp',
                width: '25%',
                type: 'number',
              },
            ]}
            rows={[
              ...allIncome.map((item) => ({
                title: typeTranslation[item.name],
                value: formatMoney(item.amount),
                deductibleValue: formatMoney(item.deductibleAmount),
              })),
              {
                title: 'Samlet sum mottatte returprovisjoner',
                value: formatMoney(totalAmountIncome),
              },
            ]}
            keyExtractor={(item, index) => `tax-earnings-${index}`}
          />
        )}
      </SectionSpacing>
    </>
  );
};
